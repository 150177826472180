<template>
  <div class="mx-3">
    <FilterDashboard />

    <div>
      <DasboardNav />
    </div>

    <div>
      <template v-if="isTableLoading">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </template>
      <table class="" >
        <thead class="px-4 py-4 ">
          <tr class="px-4 py-4 text-center tr-class" style="height: 50px">
            <th class="font-class align-text" style="width: 18%;">
              {{ $t("message.order-number") }}
            </th>
            <th class="font-class align-text" style="width: 10%;">
              {{ $t("message.account-id") }}
            </th>
            <th class="font-class align-text" style="width: 4%;">
              {{ $t("message.order-count") }}
            </th>
            <th class="font-class align-text" style="width: 10%;">
              {{ $t("message.order-total-fee") }}
            </th>
            <th class="font-class align-text" style="width: 5%">
              {{ this.$t("message.label-quarantine-type") }}
            </th>
            <th class="font-class align-text" style="width: 13%;">
              {{ $t("message.order-date") }}
            </th>
            <th class="font-class align-text" style="width: 10%;">
              {{ $t("message.delivery-date") }}
            </th>
            <th  style="width: 10%;"></th>
          </tr>
        </thead>

        <template v-if="dashboardOrderRcvd.length == 0">
          <tbody>
            <tr class="text-center">
              <td colspan="7" style="text-align: center;">
                {{ $t("message.no-data") }}
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody v-for="(order, id) in dashboardOrderRcvd" :key="id">
            <tr style="border-bottom: 1px solid #BDBDBD">
              <template>
                <td class="text-center"><a href="" @click.prevent="toOrderDetail(order.id)">{{ order.order_no }}</a></td>
                <td class="text-center"><a href="" @click.prevent="toCustomerDetail(order)">{{ order.customer_name }}</a></td>
              </template>
              <td class="text-center">{{ order.order_count }}</td>
              <td class="text-center">
                {{ moneyFormat(parseInt(order.total_order_price)) }}
              </td>
              <td class="text-center">
                {{  order.order_type_label }}
              </td>
              <td class="text-center">{{ order.created_at }}</td>
              <td class="text-center">{{ order.delivery_at }}</td>
              <td class="text-center" @click="toggle(order.id)">
                <v-btn icon style="text-transform: none;">
                  <v-icon v-if="!opened.includes(order.id)" left>{{
                    "mdi-chevron-down"
                  }}</v-icon>
                  <v-icon v-else left>{{ "mdi-chevron-up" }}</v-icon>
                  {{ $t("message.detail") }}
                </v-btn>
              </td>
            </tr>

            <template v-if="opened.includes(order.id)" class="">
              <tr
                id="example1"
                class="text-center expand-table-class hover-class"
                style="background-color: #E8EAF6;margin-top: 3em; margin-bottom: 3em;border-bottom: 1px solid #BDBDBD; padding-right: 3em"
              >
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em; width: 10%;"
                >
                  {{ $t("message.product-id") }}
                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.product-name") }}
                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.product-code") }}
                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.qty") }}

                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.price-display-per-item") }}

                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.price-display") }}
                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.tax-type-display") }}
                </th>
                <th></th>

                <!-- <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.item-amount") }}
                </th>
                <template>
                  <th
                    class="font-class"
                    style="padding-top: 1em;padding-bottom: 1em;"
                  >
                    <span v-if="order.is_bulk == 0">{{
                      $t("message.price")
                    }}</span>
                  </th>
                  <th
                    class="font-class"
                    style="padding-top: 1em;padding-bottom: 1em;"
                  >
                    <span v-if="order.is_bulk == 1">{{ $t("message.price") }}</span>
                    <span v-if="order.is_bulk == 0">{{ $t("message.total-price") }}</span>
                  </th>
                </template> -->
              </tr>
              <tr
                id="example2"
                class="hover-class"
                v-for="(detail, idx) in order.details"
                :key="idx"
                style="background-color: #E8EAF6;border-bottom: 1px solid #BDBDBD"
              >
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center; width: 10%;"
                >
                  {{ detail.item_id }}
                </td>
                <td
                  class="font-class"
                  style="padding: 0em 1.5em 0em 1.5em;"
                >
                  {{ detail.item_name }}
                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{ detail.item_code }}
                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{ detail.qty }}
                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{detail.price_display_peritem}}
                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{detail.price_display}}

                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{detail.tax_type_display}}
                </td>
                <td></td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{(order.subtotal)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_exclude_taxable_value_8 > 0"
              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-exclude-taxable-value-8") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal_exclude_taxable_value_8)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_consumption_tax_8 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-consumption-tax-8") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal_consumption_tax_8)}}

                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_exclude_taxable_value_10 > 0"

              >
                <td colspan="6"></td>
                <td  class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-exclude-taxable-value-10") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal_exclude_taxable_value_10)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_consumption_tax_10 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-consumption-tax-10") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal_consumption_tax_10)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_include_taxable_value_8 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-include-taxable-value-8") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal_include_taxable_value_8)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_consumption_taxable_8 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-consumption-taxable-8") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal_consumption_taxable_8)}}

                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_include_taxable_value_10 > 0"
              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-include-taxable-value-10") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal_include_taxable_value_10)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_consumption_taxable_10 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-consumption-taxable-10") }}
                </td>
                <td colspan="6"  class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal_consumption_taxable_10)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_non_taxable_value > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-non-taxable-value") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal_non_taxable_value)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.delivery-fee-display") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{order.delivery_fee_display}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.total_amount_w_delivery_fee_per_store > 0"
              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.total-amount-delivery-fee-store") }}
                </td>
                <td colspan="6" class="ps-8" style="text-align:start;">
                  {{toFixed(order.total_amount_w_delivery_fee_per_store)}}
                </td>
              </tr>

              
              <tr
                id="example4"
                class="hover-class"
                style="background-color: #E8EAF6"
              >
                <td colspan="6" class="pl-5">
                  <span>【備考】</span>
                  <p v-if="order.order_shipping_type == 1">置き配を希望する</p>
                  <pre>{{ order.notes }}</pre>
                </td>
                <td
                  colspan="2"
                  style="text-align: end; padding-top: 1em; padding-bottom: 2em"
                >
                  <v-btn
                    depressed
                    small
                    color="#7986cb"
                    class="white--text"
                    style="text-transform: none; margin-right: 20px;margin-bottom:4px;"
                    @click.prevent="pickUpRequest(order.id)"
                    :disabled="order.is_delivery_now == 0"
                  >
                    <span>{{ $t("message.pickup-request") }}</span>
                  </v-btn>
                  <v-btn
                    depressed
                    color="indigo"
                    class="white--text"
                    style="margin-right: 20px"
                    :loading="isLoading"
                    @click="printNote(order.id, order.delivery_at)"
                  >
                    <v-icon class="me-3">
                      mdi-printer-outline
                    </v-icon>
                    {{$t("message.print-invoice")}}
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import FilterDashboard from "../../components/FilterDashboard.vue";
import DasboardNav from "../../components/DasboardNav.vue";
import moneyFormat from "../../helpers/moneyFormat";
export default {
  name: "OrderList",
  components: {
    FilterDashboard,
    DasboardNav,
  },
  data: () => ({
    iconSearch: mdiMagnify,
    option: {},
    opened: [],
    singleExpand: false,
    expanded: [],
    isTableLoading: true,
    isLoading: false,
  }),
  watch: {
    dashboardOrderRcvd(newVal, oldVal) {},
    dashboardOrderRcvd: {
      handler: function(newVal) {},
      deep: true,
    },
  },
  computed: {
    dashboardOrderRcvd() {
      return this.$store.state.dashboard_module.dashOrderReceived;
    },
    isLoadingRec() {
      return this.$store.state.dashboard_module.isLoadingReceived;
    },
    headers() {
      return [
        {
          text: this.$t("message.order-number"),
          align: "left",
          sortable: false,
          value: "order_no",
          width: "15%",
        },
        {
          text: this.$t("message.account-id"),
          value: "customer_name",
          width: "15%",
        },
        {
          text: this.$t("message.order-count"),
          value: "order_count",
          width: "10%",
        },
        {
          text: this.$t("message.order-total-fee"),
          value: "order_fee",
          width: "13%",
        },
        {
          text: this.$t("messaage.label-quarantine-type"),
          value: "order_type_label",
          align: "center"
        },
        {
          text: this.$t("message.order-date"),
          value: "created_at",
          width: "13%",
        },
        {
          text: this.$t("message.delivery-date"),
          value: "delivery_at",
          width: "13%",
        },
        { text: "", value: "data-table-expand", width: "7%" },
      ];
    },
  },
  created() {
    this.dispatchOrderReceived();
  },
  methods: {
    toOrderDetail(order_id) {
      this.$router.push(`/order/${order_id}`)
    },
    toCustomerDetail(order) {
      this.$router.push(`/customer/detail/${order.customer_id}`)
    },
    toFixed(money){
      return money.toFixed(0)
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
    async dispatchOrderReceived() {
      this.isTableLoading = true;
      await this.$store.dispatch("dashboard_module/shopAll");
      await this.$store.dispatch("dashboard_module/deliveryDate");
      await this.$store.dispatch("dashboard_module/dashboardOrderReceived");

      this.isTableLoading = false;
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.isExpanded = true;
        this.opened.splice(index, 1);
      } else {
        this.isExpanded = false;
        this.opened.push(id);
      }
    },
    async pickUpRequest(orderId) {
      let routeName = this.$route.name;
      this.$store.dispatch("dashboard_module/changeOrderReq", {
        orderId: orderId,
        route: routeName,
      });
    },
    async printNote(orderId, delivery) {
      let routeName = this.$route.name;
      this.isLoading = true;
      let resp = await this.$store.dispatch("dashboard_module/printOrder", {
        status: 2,
        orderId: orderId,
        route: routeName,
      });

      if (resp.status == 200) {
        this.isLoading = false;
        window.open(resp.redirect_url, "_blank");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
#example1:hover {
  background-color: #e8eaf6 !important;
}
#example2:hover {
  background: #e8eaf6 !important;
}
#example3:hover {
  background: #e8eaf6 !important;
}
#example4:hover {
  background: #e8eaf6 !important;
}
#example5:hover {
  background: #e8eaf6 !important;
}
#example6:hover {
  background: #e8eaf6 !important;
}
.hover-class:hover {
  background: #e8eaf6 !important;
}
tr-class {
  background: #7986cb !important;
}
font-class {
  font-size: 14px;
}
thead {
  background: #7986cb !important;
  /* background: #3949ab !important; */
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.align-text {
  text-align: center !important;
  vertical-align: middle !important;
}
table {
  width: 100%;
}
/* td { border: solid 1px #000; } */
tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
  /* border: 1px solid rgb(132, 132, 132) */
}

tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}
tbody tr:hover:not(.v-table__expanded__content) {
  background: transparent !important;
}
</style>
